@import "./variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  font-family: "Titillium Web", "Roboto Condensed", daniel, Helvetica,
    sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: $color-black;
  height: 100%;
}

body {
  background-image: url(../img/logo-02.1.4-long.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}
