@import "../../../sass/variables.scss";

.login {
  width: 45rem;
  @media only screen and (max-width: 1500px) {
    width: 42rem;
  }
  @media only screen and (max-width: 1250px) {
    width: 37rem;
  }

  span {
    background-color: #ffffff78;
    border-radius: 1rem 0px 0px 1rem;
    color: #3c3c3c;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    height: 7rem;
    width: 7rem;
    line-height: 7rem;
    text-align: center;
  }
  input {
    height: 7rem;
  }
  &-form {
    &--email,
    &--password {
      background-color: #3b414859;
      border-radius: 0px 1rem 1rem 0px;
      color: #eee;
      margin-bottom: 2em;
      padding: 0 1.5rem;
      width: 38rem;
      font-size: 2.5rem;
      font-family: "Roboto Condensed", daniel, sans-serif;
      letter-spacing: 0.3rem;

      @media only screen and (max-width: 1500px) {
        width: 35rem;
        font-size: 2.3rem;
      }
      @media only screen and (max-width: 1250px) {
        width: 30rem;
        font-size: 2.2rem;
      }
    }

    &--submit {
      border-radius: 1rem;
      -moz-border-radius: 1rem;
      -webkit-border-radius: 1rem;

      background-color: $color-purple;
      color: #eee;
      font-size: 3rem;
      font-family: "Roboto Condensed", daniel, sans-serif;
      font-weight: bold;
      letter-spacing: 0.1rem;
      margin: 2rem 0;
      text-transform: uppercase;
      width: 45rem;
      height: 7rem;

      @media only screen and (max-width: 1500px) {
        width: 42rem;
      }
      @media only screen and (max-width: 1250px) {
        width: 37rem;
      }
      &:hover {
        background-color: $color-purple-dark;
      }
    }
    &--lock,
    &--user {
      height: 4.5rem;
      width: 4.5rem;
    }
  }

  &-signup {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    font-family: danielbd, sans-serif;
    margin-top: 1rem;
    letter-spacing: 0.1rem;

    @media only screen and (max-width: 1250px) {
      font-size: 1.9rem;
    }
    &--link {
      color: $color-white;
      font-weight: bold;
      margin: 0 1rem 0 2rem;
      text-decoration: none;

      &:hover + .login-signup--arrow {
        transform: translateX(1rem);
      }
    }
  }
}
