@import "../../sass/variables.scss";

.homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  // padding-top: 8rem
  padding: 8rem 12% 0 12%;
  height: 100dvh;

  background-image: linear-gradient(
    -80deg,
    rgba($color-black, 1) 0%,
    rgba($color-black, 0.8) 50%,
    transparent 55%
  );

  background-size: 100%;

  @media only screen and (max-width: 1700px) {
    padding: 0 10%;
  }
  @media only screen and (max-width: 1500px) {
    padding: 0 7%;
  }
  @media only screen and (max-width: 1250px) {
    padding: 0 5%;
  }

  &-phrase {
    width: 45rem;
    margin: 0rem 0 10rem 0;
    text-align: center;

    @media only screen and (max-width: 1500px) {
      width: 42rem;
    }
    @media only screen and (max-width: 1250px) {
      width: 37rem;
    }
    &--title {
      font-family: "Titillium Web", "Roboto Condensed", daniel, sans-serif;
      font-weight: 600;
      letter-spacing: 0.3rem;
      font-size: 3.5rem;
    }
  }
}
