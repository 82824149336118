@import "../sass/variables.scss";

.App {
  min-height: 100dvh;
  height: 100%;
  width: 100%;

  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

//headers
h1 {
  color: $color-white;
  font-family: "Roboto Condensed", daniel, sans-serif;
  font-size: 5rem;
  font-weight: 400;
  position: relative;
}

h2 {
  color: $color-white;
  font-family: "Roboto Condensed", daniel, sans-serif;
  font-size: 2rem;
  font-weight: 400;
  position: relative;
}
p {
  color: $color-white;
  font-family: "Titillium Web", "Roboto Condensed", daniel, sans-serif;
  font-size: 1.7rem;
  font-weight: 400;
  position: relative;
}

//**** MEDIA QUERIES

@media only screen and (max-width: 800px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }
  p {
    font-size: 1.9rem;
  }
}
