@import "../sass/variables.scss";

$bg: #f3f8fa;
$color: #fff;
$black: #a17bb0;
$bg: $color-purple;
// $color: $color-purple;

@mixin transition(
  $property: all,
  $duration: 0.45s,
  $ease: cubic-bezier(0.65, 0, 0.076, 1)
) {
  transition: $property $duration $ease;
}

button {
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;

  &.button {
    transform: scale(1.5);
    border-radius: 1.625rem;
    z-index: 10000;
    position: fixed;
    top: 15rem;
    right: 10rem;
    width: 8rem;
    height: auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;

    // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    //   rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; // less seable shadow

    @media only screen and (max-width: 1050px) {
      right: 5rem;
    }
    @media only screen and (max-width: 800px) {
      transform: scale(1.4);
      top: 2.5rem;
      right: 10rem;
    }
    @media only screen and (max-width: 500px) {
      top: 2rem;
      right: 10rem;
    }
    @media only screen and (max-width: 370px) {
      left: 20rem;
    }
    @media only screen and (max-width: 350px) {
      left: 17rem;
    }

    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: $black;
      border-radius: 1.625rem;
      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $color;
        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;
          &::before {
            position: absolute;
            content: "";
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }
    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, 0.076, 1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 0;
      margin: 0 0 0 1.85rem;
      color: $black;
      font-weight: 700;
      line-height: 1.6;
      text-align: center;
      text-transform: uppercase;
    }
  }
  &:hover,
  &:focus,
  &:active {
    .circle {
      width: 100%;
      .icon {
        &.arrow {
          background: $color;
          transform: translate(1rem, 0);
          &::before {
            border-top: 0.125rem solid $color;
            border-right: 0.125rem solid $color;
          }
        }
      }
    }
    .button-text {
      color: $color;
    }
  }
}
