@import "../../sass/variables.scss";

.homepage-mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  width: 100%;
  background-image: url(../../img/logo-02.1c.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}
