@import "../../../sass/variables.scss";

.mobile-login {
  margin: auto;
  height: 70%;
  width: 70%;
  background-color: rgba(0, 0, 0, 0.9);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  border-radius: 1rem;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 5;

  @media only screen and (max-width: 800px) {
    width: 80%;
  }
  @media only screen and (max-width: 500px) {
    width: 90%;
  }

  span {
    background-color: #ffffff78;
    border-radius: 1rem 0px 0px 1rem;
    color: #3c3c3c;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    height: 7rem;
    width: 7rem;
    line-height: 7rem;
    text-align: center;

    @media only screen and (max-width: 500px) {
      height: 5rem;
      width: 5rem;
      line-height: 5rem;
    }
    @media only screen and (max-width: 380px) {
      height: 5rem;
      width: 5rem;
      line-height: 5rem;
    }
  }
  input {
    height: 7rem;

    @media only screen and (max-width: 500px) {
      height: 5rem;
    }
    @media only screen and (max-width: 380px) {
      height: 5rem;
    }
  }
  &--close_btn {
    position: relative;
    font-size: 3rem;
    background: transparent;
    border-color: transparent;
    color: $color-purple;
    transition: all ease-out 0.5s;
    cursor: pointer;
    margin-top: 2%;
    z-index: 10;

    &:hover {
      color: $color-purple-dark;
    }
  }

  &--form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    &_email,
    &_password {
      background-color: #3b414859;
      border-radius: 0px 1rem 1rem 0px;
      color: #eee;
      margin-bottom: 2rem;
      padding: 0 1.5rem;
      width: 30rem;
      font-size: 2rem;
      font-family: "Roboto Condensed", daniel, sans-serif;
      letter-spacing: 0.4rem;

      @media only screen and (max-width: 500px) {
        font-size: 1.5rem;
        width: 22rem;
      }
      @media only screen and (max-width: 380px) {
        width: 22rem;
        font-size: 1rem;
      }
    }

    &_submit {
      border-radius: 1rem;
      -moz-border-radius: 1rem;
      -webkit-border-radius: 1rem;

      background-color: $color-purple;
      color: #eee;
      font-size: 3rem;
      font-family: "Roboto Condensed", daniel, sans-serif;
      font-weight: bold;
      letter-spacing: 0.1rem;
      margin: 2rem 0;
      text-transform: uppercase;
      width: 37rem;
      height: 7rem;
      &:hover {
        background-color: $color-purple-dark;
      }

      @media only screen and (max-width: 500px) {
        font-size: 2rem;
        height: 5rem;
        width: 27rem;
      }
    }
    &_lock,
    &_user {
      height: 4.5rem;
      width: 4.5rem;

      @media only screen and (max-width: 500px) {
        height: 2.7rem;
        width: 2.7rem;
      }
      @media only screen and (max-width: 380px) {
        height: 2.3rem;
        width: 2.3rem;
      }
    }
  }

  &--signup {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    font-family: danielbd, sans-serif;
    letter-spacing: 0.1rem;
    margin-bottom: 5%;

    @media only screen and (max-width: 800px) {
      font-size: 2rem;
    }
    @media only screen and (max-width: 500px) {
      font-size: 1.6rem;
    }
    @media only screen and (max-width: 380px) {
      font-size: 1.2rem;
    }

    &_link {
      color: $color-white;
      font-weight: bold;
      margin: 0 1rem 0 2rem;
      text-decoration: none;

      &:hover + .mobile-login--signup_arrow {
        transform: translateX(1rem);
      }
    }
  }
}

.mobile-homepage--phrase {
  // margin: 15rem 0 10rem 0;
  text-align: center;

  &_title {
    font-family: "Titillium Web", "Roboto Condensed", daniel, sans-serif;
    font-weight: 700;
    @media only screen and (max-width: 380px) {
      font-size: 3rem;
    }
  }
}
