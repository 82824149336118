@import "./../../sass/variables.scss";
@import "../../sass/animations.scss";

.howitworks {
  display: flex;
  flex-direction: column;
  padding: 10rem 7rem 0 7rem;
  justify-content: center;
  min-height: 100dvh;

  background-image: url(../../img/logo-02.1c.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}

.container {
  &-1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-2 {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.title {
  color: $color-purple;
  font-weight: 700;
  font-size: 5rem;
  text-transform: uppercase;
}
.text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem 4rem 1rem 4rem;
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
  &:last-child {
    padding-top: 0;
    padding-bottom: 4rem;
  }
}
.text-container {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 2rem;
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.432);
  width: 75%;
}

.section {
  &-1 {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  &-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    flex: 1;
  }
}

.video {
  background-color: rgba(255, 255, 255, 0.118);
  border-radius: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  margin: 2rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mp4 {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

//**** MEDIA QUERIES

@media only screen and (max-width: 1700px) {
  .text {
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: 1600px) {
  .howitworks {
    background-image: url(../../img/logo-blured.jpg);
    padding-top: 20rem;
  }
  .title {
    margin-bottom: 5rem;
  }
  .container {
    &-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }
  .text-container {
    width: 100%;
    margin-bottom: 5rem;
  }
  .text {
    font-size: 2rem;
  }

  .section {
    &-1 {
      align-items: center;
      justify-content: center;
    }
    &-2 {
      align-items: center;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .text {
    font-size: 1.7rem;
  }
}

@media only screen and (max-width: 800px) {
  .howitworks {
    padding: 15rem 5rem 5rem 5rem;
  }
  .text {
    padding: 2rem;
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 500px) {
  .text-container {
    box-shadow: none;
  }
  .howitworks {
    padding: 15rem 3rem 5rem 3rem;
  }
  .title {
    font-size: 3rem;
  }
}
