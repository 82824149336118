@import "../sass/variables.scss";

nav {
  position: fixed;
  width: 100%;
  z-index: 100;
  background: $background-header;
  box-shadow: $shadow-navbar;

  &-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.nav-toggle {
  color: $color-white;
  margin-right: 2rem;
  font-size: 3rem;
  background: transparent;
  border-color: transparent;
  transition: $transition;
  cursor: pointer;

  &:hover {
    color: $color-purple;
    transform: rotate(90deg);
  }
}
.logo {
  height: 7rem;
  transition: all 0.2s;
}

.links a {
  color: $color-white;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  display: block;
  padding: 0.5rem 1rem;
  transition: $transition;
  text-decoration: none;
}

.links-container {
  height: 0;
  overflow: hidden;
  transition: $transition;
}
.show-container {
  height: 10rem;
}

.link {
  text-align: center;
  transition: all 0.3s;

  &-active {
    text-align: center;
    color: $color-purple;
    opacity: 0.5;
  }
  &:hover {
    color: $color-purple;
    padding: 0;
    background: transparent;
  }
}

//**** MEDIA QUERIES

@media screen and (min-width: 900px) {
  .nav-center {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
  }
  .nav-header {
    padding: 0;
  }
  .nav-toggle {
    display: none;
  }
  .links-container {
    height: auto !important;
    overflow: visible;
  }
  .links {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;

    & a {
      padding: 0;
      margin: 0 5rem 0 0;
    }
  }

  .logo {
    height: 6rem;
    &:hover {
      transform: rotate(-5deg);
    }
  }

  .links a {
    letter-spacing: 0.1rem;
  }
  .link {
    text-align: center;
  }
  .link-active {
    transform: scale(1.1);
    font-weight: bold;
  }
}

@media only screen and (max-width: 800px) {
  .link {
    text-align: center;
  }
  .link-active {
    font-weight: bold;
  }
}

@media only screen and (max-width: 500px) {
  .nav-toggle {
    font-size: 2.5rem;
    border: none;
  }
  .link {
    font-size: 2rem;
    text-align: center;
  }
  .link-active {
    font-weight: bold;
    font-size: 2.2rem;
  }

  .logo {
    height: 5rem;
  }
}
