@import "./../../sass/variables.scss";
@import "../../sass/animations.scss";

.team {
  display: flex;
  flex-direction: column;
  padding: 15rem;
  justify-content: center;
  height: 100dvh;

  background-image: url(../../img/logo-02.1c.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  &-container {
    &-1 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-2 {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &-section {
    &-1 {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    &-2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &-title {
    position: absolute;
    bottom: 5rem;
    right: 10%;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-family: danielbd;
    font-weight: 900;
    font-size: 7rem;
    color: $color-purple;
  }
  &-person {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;

    &--img {
      margin: 0 3rem;
      object-fit: contain;
      background-color: $color-white;
      border-radius: 50%;
      height: 13rem;
      width: 13rem;
      box-shadow: 10px 5px 5px rgb(0, 0, 0);
      border: solid $color-purple 4px;
    }
    &--text {
      animation: moveInLeftStart 1s;
    }
    &--name {
      font-size: 2.5rem;
    }
    &--job {
      font-style: italic;
      font-size: 2rem;
    }
  }
}

.video {
  background-color: rgba(255, 255, 255, 0.118);
  border-radius: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  margin: 2rem 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mp4 {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

//**** MEDIA QUERIES

@media only screen and (max-width: 1400px) {
  .team {
    background-image: url(../../img/logo-blured.jpg);
    padding-top: 20rem;
    min-height: 100dvh;
    height: fit-content;

    &-person {
      flex-direction: column;
    }

    &-title {
      position: relative;
      bottom: auto;
      right: auto;
      align-self: center;
      margin-bottom: 5rem;
      font-size: 4rem;
      color: $color-white;
    }
    &-container {
      &-2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }
    }
    &-container {
      flex-direction: row;
      width: 100%;
      margin-bottom: 5rem;
    }

    &-section {
      &-1 {
        align-items: center;
        justify-content: center;
      }
      &-2 {
        flex-direction: row;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .team {
    padding: 20rem 4rem;
  }
  .team-container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 800px) {
  .team {
    padding: 15rem 4rem;
    padding-bottom: 5rem;
  }
  .team-title {
    font-size: 3rem;
  }
}
@media only screen and (max-width: 500px) {
  .team-title {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 400px) {
  .team {
    &-person {
      margin: 2rem 0;
    }
    &-title {
      font-size: 2rem;
    }
  }
}
