//colors
$color-red: #a80b0b;

$color-green-light: #7ed56f;
$color-green-dark: #2e742f;
$color-purple: #9c489e;
$color-purple-dark: #7d377e;

$color-yelow: #ffb900;
$color-orange-dark: #bf7a04;

$color-blue: #439bfa;
$color-blue-light: #5aafff;
$color-blue-dark: #094d9fd2;

$color-grey-light-1: #fcfcfc;
$color-grey-light-2: #f2eeee;
$color-grey-light-3: #ccc;
$color-grey-light-4: #b6b6b6;
$color-grey-dark-1: #999;
$color-grey-dark-2: #777;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;

$background-header: #16222e;

$transition: all 0.3s linear;
$shadow-light: 0 5px 15px rgba(0, 0, 0, 0.1);
$shadow-dark: 0 5px 15px rgba(0, 0, 0, 0.2);
$shadow-navbar: rgba(0, 0, 0, 0.95) 0px 25px 20px -10px;

//screen break points
// $bp-largest: 93.75em;
// $bp-large: 75em;
// $bp-medium: 62em;
// $bp-medium-low: 44em;
// $bp-small: 36em;
// $bp-smallest: 20.625em;

// $bp-name-largest: 106.975em;
// $bp-name-large: 82.5em;
// $bp-name-medium: 53.875em;
// $bp-download-btn-transfer: 50em

/* File: font.css */
@font-face {
  font-family: "daniel";
  src: url(../Data/Fonts/daniel/daniel.ttf);
  src: url(../Data/Fonts/daniel/daniel.ttf) format("ttf");
}
@font-face {
  font-family: "danielbd";
  src: url(../Data/Fonts/daniel/danielbd.ttf);
  src: url(../Data/Fonts/daniel/danielbd.ttf) format("ttf");
}
@font-face {
  font-family: "danielbk";
  src: url(../Data/Fonts/daniel/danielbk.ttf);
  src: url(../Data/Fonts/daniel/danielbk.ttf) format("ttf");
}
